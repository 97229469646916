import React, { useEffect, useState } from 'react';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import styles from './JobsStatics.module.css';
import { API_URL, getToken } from '../../Helpers/constants';
import { Link } from 'react-router-dom';

export default function JobsStatics() {
    const token = getToken();

    const [jobData, setJobData] = useState(null);
    const [joinedData, setJoinedData] = useState([]);
    const [webinarStats, setWebinarStats] = useState(null);
    const [meetingStats, setMeetingStats] = useState(null);
    const [taskSubmissions, setTaskSubmissions] = useState(null);
    const [totalStudents, setTotalStudents] = useState(null);
    const [totalEarnings, setTotalEarnings] = useState(null); // Add state for total earnings

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const myHeaders = new Headers();
                myHeaders.append("accept", "application/json");
                myHeaders.append("Authorization", `Bearer ${token}`);
                myHeaders.append("X-CSRFToken", "wK7YB2jTa3u12aKS64ttydcuEpxgU4ihFeZBv33UyDVhyg3qsqtYK2qy5bGOT2Kz");

                const requestOptions = {
                    method: "GET",
                    headers: myHeaders,
                    redirect: "follow",
                };

                // Fetch job statistics
                const jobResponse = await fetch(`${API_URL}/students/freelance-status-updated/`, requestOptions);
                const jobResult = await jobResponse.json();
                setJobData(jobResult);
                console.log('jobResult', jobResult)

                // Fetch joined status
                const joinedResponse = await fetch(`${API_URL}/students-joined/`, requestOptions);
                const joinedResult = await joinedResponse.json();
                setTotalStudents(joinedResult);
                setJoinedData([
                    { label: "Active", value: joinedResult.joined_percentage, count: joinedResult.joined_count },
                    { label: "Not Active", value: joinedResult.not_joined_percentage, count: joinedResult.not_joined_count },
                ]);

                // Fetch total earnings
                const earningsResponse = await fetch(`${API_URL}/total-earnings/`, requestOptions);
                const earningsResult = await earningsResponse.json();
                setTotalEarnings(earningsResult.total_earnings);

                // Fetch other data...
                const webinarResponse = await fetch(`${API_URL}/webinar-statistics/`, requestOptions);
                const webinarResult = await webinarResponse.json();
                setWebinarStats(webinarResult);
                console.log('webinarResult', webinarResult)

                const meetingResponse = await fetch(`${API_URL}/meeting-statistics/`, requestOptions);
                const meetingResult = await meetingResponse.json();
                setMeetingStats(meetingResult);
                console.log('meetingResult', meetingResult)

                const taskResponse = await fetch(`${API_URL}/task-statistics/`, requestOptions);
                const taskResult = await taskResponse.json();
                setTaskSubmissions(taskResult.task_submissions);

                setLoading(false);
            } catch (err) {
                console.error("Error fetching data:", err);
                setError("Failed to fetch data");
                setLoading(false);
            }
        };

        fetchData();
    }, [token]);

    if (loading) {
        return <div>Loading Dashboard...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    // Chart data preparation
    const jobChartData = [
        { label: "Full-Time", value: jobData.total_full_time_jobs_percentage, count: jobData.total_full_time_jobs_count },
        { label: "Part-Time", value: jobData.total_part_time_jobs_percentage, count: jobData.total_part_time_jobs_count },
        { label: "Freelance", value: jobData.total_freelance_jobs_percentage, count: jobData.total_freelance_jobs_count },
    ];
    const jobChartDataTwo = [
        { label: "Full-Time", value: jobData.percentage_of_passed_full_time_jobs, count: jobData.no_of_passed_full_time_jobs },
        { label: "Part-Time", value: jobData.percentage_of_passed_part_time_jobs, count: jobData.no_of_passed_part_time_jobs },
        { label: "Freelance", value: jobData.percentage_of_passed_freelance_jobs, count: jobData.no_of_passed_freelance_jobs },
    ];


    const meetingRatingData = [
        { label: "Rating 1", value: meetingStats.rating_1_percentage || 0, count: meetingStats.rating_1_count },
        { label: "Rating 2", value: meetingStats.rating_2_percentage || 0, count: meetingStats.rating_2_count },
        { label: "Rating 3", value: meetingStats.rating_3_percentage || 0, count: meetingStats.rating_3_count },
        { label: "Rating 4", value: meetingStats.rating_4_percentage || 0, count: meetingStats.rating_4_count },
        { label: "Rating 5", value: meetingStats.rating_5_percentage || 0, count: meetingStats.rating_5_count },
    ];

    const arcLabelFormatter = (item) => `${item.value.toFixed(1)}% (${item.count})`;

    const isMeetingRatingsZero = meetingRatingData.every(data => data.value === 0);
    const isWebinarZero = webinarStats.joined_percentage === 0 && webinarStats.not_joined_percentage === 0;

    return (
        <div className={styles.dashboard}>
            <h1 className={styles.title}>Overview</h1>

            <div className={styles.cardsContainer}>
                <div className={styles.card}>
                    <h3>Total Students</h3>
                    <p className={styles.count}>{totalStudents.all_student}</p>
                </div>
                {/* <div className={styles.card}>
                    <h3>Students Without Jobs</h3>
                    <p className={styles.count}>{jobData.zero_jobs}</p>
                </div> */}

                {/* <div className={styles.card}>
                    <h3>Passed Students</h3>
                    <p className={styles.count}>{jobData.no_student_finished_course}</p>
                    <Link to="/student-passed" className={styles.link}>Show Student Passed</Link>
                </div> */}
                {/* <div className={styles.card}>
                    <h3>Students In Progress </h3>
                    <p className={styles.count}>{jobData.no_student_in_progress}</p>
                </div> */}
                <div className={styles.card}>
                    <h3>Total Jobs  </h3>
                    <p className={styles.count}>{jobData.total_jobs}</p>
                </div>
                <div className={styles.card}>
                    <h3> In progress jobs </h3>
                    <p className={styles.count}>{jobData.total_in_progress_jobs}</p>
                </div>
                <div className={styles.card}>
                    <h3> Passed jobs </h3>
                    <p className={styles.count}>{jobData.total_passed_jobs}</p>
                </div>
                <div className={styles.card}>
                    <h3>Task Submissions</h3>
                    <p className={styles.count}>{taskSubmissions}</p>
                </div>
                <div className={styles.card}>
                    <h3>Total Webinars</h3>
                    <p className={styles.count}>{webinarStats.total_webinars}</p>
                </div>
                <div className={styles.card}>
                    <h3>Finished Webinars</h3>
                    <p className={styles.count}>{webinarStats.finished_webinars}</p>
                </div>
                <div className={styles.card}>
                    <h3>Non Finished Webinars</h3>
                    <p className={styles.count}>{webinarStats.non_finished_webinars}</p>
                </div>
                <div className={styles.card}>
                    <h3>Total 1:1 Sessions</h3>
                    <p className={styles.count}>{meetingStats.total_meetings}</p>
                </div>
                <div className={styles.card}>
                    <h3>Total 1:1 reserverd sessions</h3>
                    <p className={styles.count}>{meetingStats.total_reserverd_meetings}</p>
                </div>
                <div className={styles.card}>
                    <h3>Total 1:1 Finished sessions</h3>
                    <p className={styles.count}>{meetingStats.total_meeting_attendances}</p>
                </div>
                <div className={styles.card}>
                    <h3>Total Earnings</h3> {/* New Card for Total Earnings */}
                    <p className={styles.count}>{totalEarnings} $</p>
                </div>
            </div>

            <div className={styles.chartsContainer}>
                <div className={styles.chartItem}>
                    <h2>Total Jobs(by category)
                    </h2>
                    <div className={styles.chartComponent}>
                        <PieChart
                            series={[{ data: jobChartData, arcLabel: arcLabelFormatter, arcLabelMinAngle: 35, arcLabelRadius: '60%' }]}
                            sx={{ [`& .${pieArcLabelClasses.root}`]: { fontWeight: 'bold' } }}
                            {...size}
                        />
                      
                    </div>
                </div>
                <div className={styles.chartItem}>
                    <h2>Passed Jobs (by category)
                    </h2>
                    <div className={styles.chartComponent}>
                        <PieChart
                            series={[{ data: jobChartDataTwo, arcLabel: arcLabelFormatter, arcLabelMinAngle: 35, arcLabelRadius: '60%' }]}
                            sx={{ [`& .${pieArcLabelClasses.root}`]: { fontWeight: 'bold' } }}
                            {...size}
                        />
                    </div>
                </div>
                <div className={styles.chartItem}>
                    <h2>Active Students </h2>
                    <div className={styles.chartComponent}>
                        <PieChart
                            series={[{ data: joinedData, arcLabel: arcLabelFormatter, arcLabelMinAngle: 35, arcLabelRadius: '60%' }]}
                            sx={{ [`& .${pieArcLabelClasses.root}`]: { fontWeight: 'bold' } }}
                            {...size}
                        />
                    </div>
                </div>
                <div className={styles.chartItem}>
                    <h2>Webinar Status</h2>
                    {isWebinarZero ? <div>No webinar data available (0%)</div> :
                        <div className={styles.chartComponent}>

                            <PieChart
                                series={[{ data: [{ label: "Joined", value: webinarStats.joined_percentage }, { label: "Not Joined", value: webinarStats.not_joined_percentage }], arcLabel: arcLabelFormatter, arcLabelMinAngle: 35, arcLabelRadius: '60%' }]}
                                sx={{ [`& .${pieArcLabelClasses.root}`]: { fontWeight: 'bold' } }}
                                {...size}
                            />
                        </div>
                    }
                </div>
                <div className={styles.chartItem}>
                    <h2>Meeting Ratings</h2>
                    {isMeetingRatingsZero ? <div>No ratings data available (0%)</div> :
                        <div className={styles.chartComponent}>

                            <PieChart
                                series={[{ data: meetingRatingData, arcLabel: arcLabelFormatter, arcLabelMinAngle: 35, arcLabelRadius: '60%' }]}
                                sx={{ [`& .${pieArcLabelClasses.root}`]: { fontWeight: 'bold' } }}
                                {...size}
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

const size = {
    width: 600,
    height: 250,
};
